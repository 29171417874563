import * as S from './styles';

const CountdownCell = (props) => {
  return (
    <S.Container>
      <S.TimeValue>{props.timeValue}</S.TimeValue>
      <S.TimeLabel>{props.timeLabel}</S.TimeLabel>
    </S.Container>
  );
};

export default CountdownCell;
