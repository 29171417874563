import * as S from './styles';
import newCycleStart from './Assets/newCycle.png';
import gigiga from './Assets/gigiga.svg';
import Countdown from './Countdown';
import { useCallback, useEffect, useRef, useState } from 'react';
import UseAuthentication from '~/hooks/NexAdmin/AuthenticationHook';

const CountdownFev2025 = () => {
  // const endInterval = '2025-03-01T11:00:00.000Z';
  const { api } = UseAuthentication();
  const endInterval = useRef(null);
  const [loading, setLoading] = useState(true);

  const countdownInterval = useCallback(async () => {
    try {
      const response = await api.get('/channel/countdown');
      endInterval.current = response.data.end_date;
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch countdown data:', error);
      setLoading(false);
    }
  }, [api]);

  useEffect(() => {
    countdownInterval();
  }, [countdownInterval]);

  return (
    <S.Container>
      <S.ImgStartsAt src={newCycleStart}></S.ImgStartsAt>
      <S.RedContainer>
        <S.ImgGigiga src={gigiga} />
        <Countdown finishDate={endInterval.current} />
        <S.Text>
          Fique de olho!{' '}
          <S.TextBreak> Muito em breve, você poderá resgatar</S.TextBreak>{' '}
          <S.TextBold>prêmios incríveis</S.TextBold>.
        </S.Text>
      </S.RedContainer>
    </S.Container>
  );
};

export default CountdownFev2025;
