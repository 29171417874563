import CountdownPage from '~/components/CountDownFinal/index';
import CountdownFev2025 from '~/components/CountDowns/CountdownFev2025';

const Countdown = () => {
  return (
    <>
      <CountdownFev2025 />
    </>
  );
};

export default Countdown;
