import * as S from './styles';
import CountdownCell from './CountdownCell';
import moment from 'moment';

import { useEffect, useState } from 'react';

const Countdown = ({ finishDate }) => {
  const [time, setTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const updateCountdown = () => {
      const then = moment(finishDate);
      const now = moment();
      const countdown = moment.duration(then.diff(now));

      if (countdown.asSeconds() <= 0) {
        setTime({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        return;
      }

      setTime({
        days: countdown.days(),
        hours: countdown.hours(),
        minutes: countdown.minutes(),
        seconds: countdown.seconds(),
      });
    };

    updateCountdown();
    const interval = setInterval(updateCountdown, 1000);

    return () => clearInterval(interval);
  }, [finishDate]);

  return (
    <S.Container>
      <CountdownCell timeValue={time.days} timeLabel={'dias'} />
      <CountdownCell timeValue={time.hours} timeLabel={'horas'} />
      <CountdownCell timeValue={time.minutes} timeLabel={'minutos'} />
      <CountdownCell timeValue={time.seconds} timeLabel={'segundos'} />
    </S.Container>
  );
};

export default Countdown;
