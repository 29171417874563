import styled from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  width: 240px;
  height: 240px;
  background-color: white;
  border: 4px solid #450b13;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 1%;
  flex-shrink: 0;

  ${media.lessThan('1700px')`
    width: 170px;
    height: 170px;
    margin-right: 0.5%;
    margin-top: -2rem;
    `}

  ${media.lessThan('1100px')`
    width: 130px;
    height: 130px;
    `}

  ${media.lessThan('767px')`
      width: 100px;
      height: 100px;
      margin-top: -1rem;
      border: 2px solid #450b13;
      margin: 0.5%;
  `}
`;

export const TimeValue = styled.p`
  font-size: 12rem;
  color: #e74239;
  font-family: 'AMXBlack', Times, serif;
  font-weight: ${({ theme }) => theme.font.extraBold};
  margin-top: -1rem;

  ${media.lessThan('1700px')`
    font-size: 10rem;

    `}

  ${media.lessThan('1100px')`
    font-size: 8.3rem;
    
    `}

  ${media.lessThan('850px')`
    font-size: 5.6rem;
    `}
`;

export const TimeLabel = styled.label`
  font-weight: ${({ theme }) => theme.font.medium};
  font-family: 'AMXItalic', Times, serif;
  color: #450b13;
  margin-top: -2.4rem;
  font-size: ${({ theme }) => theme.font.sizes.medium};

  ${media.lessThan('1700px')`
  font-size: ${({ theme }) => theme.font.sizes.small};
    `}

  ${media.lessThan('1100px')`
    margin-top: -1.6rem;

    `}

  ${media.lessThan('767px')`
    font-size: 1rem;
    margin-top: -0.9rem;

    `}
`;
