import styled from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: end;
  position: relative;
  background-image: linear-gradient(to right, #dcd5c8, #aca59c);
`;

export const RedContainer = styled.div`
  height: 55%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  bottom: 0;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.red};

  ${media.lessThan('767px')`
    height: 38vh;
    display: flex;
    position: relative;
  justify-content: space-evenly;
  `}
`;

export const ImgStartsAt = styled.img`
  width: 490px;
  position: absolute;
  align-items: center;
  bottom: 38%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);

  ${media.lessThan('1700px')`
    width: 340px;
    bottom: 36%
    `}

  ${media.lessThan('1100px')`
    width: 220px;
    bottom: 39%
    `}

  ${media.lessThan('767px')`
    top: 13%;
    width: 260px;
`}
`;

export const ImgGigiga = styled.img`
  height: 62%;
  position: fixed;
  z-index: 2;
  bottom: 0;
  left: 17%;
  transform: translateX(-50%);

  ${media.lessThan('1100px')`
    height: 60%;
    
    `}

  ${media.lessThan('767px')`
    height: 35vh;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3; 
  `}
`;

export const Text = styled.p`
  color: white;
  font-size: ${({ theme }) => theme.font.sizes.xlarge};
  font-family: 'AMXItalic', Times, serif !important;
  font-weight: 100;
  text-align: center;
  display: inline-block;

  ${media.lessThan('1700px')`
  font-size: ${({ theme }) => theme.font.sizes.medium};

    `}

  ${media.lessThan('1100px')`
  font-size: ${({ theme }) => theme.font.sizes.xsmall};
  font-weight: 100;
  `}
  
  ${media.lessThan('767px')`
    font-size: ${({ theme }) => theme.font.sizes.small};
 

    `}
`;

export const TextBreak = styled.span`
  ${media.lessThan('767px')`
    display:block;
    clear:left;
    line-height: 1.7;
    `}
`;

export const TextBold = styled.p`
  font-weight: ${({ theme }) => theme.font.bold};
  display: inline-block;
`;
