import styled from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 90px;

  ${media.lessThan('1700px')`
    width: 100%;

`}

  ${media.lessThan('767px')`
    width: 100%;
    margin-top: 0px;
`}
`;
